import React from "react"
import { SetupContexts, PageWrapper, StationsList } from "@geome/shell-directory-pages"

const StationListDirectoryPage = (props) => {
  return (
    <SetupContexts {...props.setupContextsProps}>
      <PageWrapper {...props.pageWrapperProps}>
        <StationsList {...props.stationListProps} />
      </PageWrapper>
    </SetupContexts>
  )
}

export default StationListDirectoryPage
